// IMPORTS
@import './sass/vars.scss';
@import './partials/hero/hero';
@import './partials/aboutUs/aboutUs';
@import './partials/program/program';
@import './partials/proposals/proposals';
@import './partials/footer/footer';
@import './partials/modalForm/modalForm';
@import './partials/contentBorder/contentBorder';
@import './partials/backdrop/backdrop';
@import './partials/spinner/spinner';

// policy imports
@import './sass/policy.scss';
@import './partials/policyHeader/policyHeader';

// FONTS
@font-face {
  font-family: 'SignPainter';
  src: url('./assets/fonts/signpainter_housescript.otf') format('woff');
}

// BASE STYLES
// * {
//   outline: 1px solid green;
// }

@media screen and (min-width: 320px) {
  html,
  body {
    overflow-x: hidden;
  }
}

body {
  background-color: #fff6fb;
}

ul {
  list-style: none;
}

li {
  display: block;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.scrollBlock {
  overflow: hidden;
}

.blur {
  filter: blur(9px);
}

// ADAPTIVE

.container {
  margin-left: auto;
  margin-right: auto;
}

// Mobile
@media screen and (max-width: 767px) {
  .container {
    // max-width: 480px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

// Tablet
@media screen and (min-width: 768px) {
  .container {
    width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

// Desktop
@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
    padding-left: 48px;
    padding-right: 48px;
  }
}
