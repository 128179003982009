.policy__container {
  min-height: 70vh;
}

.policy__content-wrapper {
  font-family: 'Avenir Next Cyr', sans-serif;
  font-size: 14px;
  margin-bottom: 48px;
}

.policy__main-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-family: 'Alice', sans-serif;
}
.policy__title {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: 'Alice', sans-serif;
}

.policy__list {
  list-style-type: disc;
  padding-left: 30px;

  li {
    display: list-item;
  }
}

// Mobile
@media screen and (max-width: 767px) {
}

// Tablet
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 1199px) {
}

// Desktop
@media screen and (min-width: 1200px) {
  .policy__main-title {
    font-size: 28px;
  }

  .policy__title {
    font-size: 18px;
  }

  .policy__content-wrapper {
    font-size: 16px;
  }
}
