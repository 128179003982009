.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 1px;
  left: 1px;

  backdrop-filter: blur(9px);
  background-color: rgba(108, 95, 108, 0.6);
}
