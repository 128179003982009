.container.container__hero {
  width: 100%;
  background-color: #ba89a2;
}

.nav-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}
.nav-img {
  width: 80px;
  transform: translateY(5px);

  margin-left: 20px;
}

.hero {
  height: 845px;
  padding-top: 130px;
  background-image: url('./assets/images/photo/hero-bcg-mobile.png');
  background-repeat: no-repeat;
  background-color: #ba89a2;

  font-family: 'Avenir Next Cyr', sans-serif;
  color: #f6ef00;
  margin-right: -16px;
  margin-left: -16px;
}

.nav {
  font-style: normal;
  font-weight: 600;
  line-height: 96%;
  font-family: 'Avenir Next Cyr', sans-serif;
}

.nav-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.burger-menu {
  padding-top: 60px;
  padding-right: 40px;

  max-width: 768px;
  width: 100%;
  height: 600px;

  position: absolute;
  top: 0;
  right: 0;

  z-index: 100;

  pointer-events: all;
}

.burger-menu__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.burger-menu__open-icon,
.burger-menu__close-icon {
  fill: #f6ef00;
  position: relative;

  &:active {
    fill: #f7f6d9;
  }
}

.burger-menu__button {
  background: none;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;

  margin-bottom: 60px;

  &:hover {
    transform: scale(1.1);
  }

  transition: transform 100ms var(--cubic);
}
.burger-nav .nav-list {
  display: flex;
  flex-direction: column;

  width: 180px;
  gap: 56px;
}

.nav-link {
  color: #f6ef00;
  line-height: 96%;

  &:hover {
    color: #f7f6d9;
  }

  transition: all 100ms var(--cubic);
}

.ivent-time {
  margin-bottom: 44px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;

  text-align: center;
}

.hero-desktop-flower {
  display: none;
}

.hero-logo-icon,
.hero-line,
.hero-arrow {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.hero-line,
.hero-arrow {
  fill: #ebe757;
}

.hero-logo-icon {
  fill: #f2cc21;
  margin-bottom: 176px;

  padding: 0px 20px;
  width: 100%;
}

.hero-text {
  display: block;
  max-width: 270px;
  margin: 27px auto;

  font-weight: 600;
  font-size: 16px;
  line-height: 96%;

  text-align: center;
}

.hero-arrow-link {
  display: block;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
}

// Mobile
@media screen and (max-width: 767px) {
  .hero {
    background-size: 400px;
    background-position-y: bottom;
    background-position-x: right;
  }
  .nav {
    font-size: 24px;
  }
}

// Tablet
@media screen and (min-width: 768px) {
  .hero {
    background-position-y: -230px;
    background-size: 700px;
    background-position-y: -250px;
    background-position-x: right;

    margin-right: -32px;
    margin-left: -32px;
  }

  .nav-link {
    font-size: 24px;
  }

  .ivent-time {
    font-size: 20px;
    font-weight: 600;
  }

  .hero-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .nav-wrapper {
    display: none;
  }

  .hero-arrow-desktop {
    display: none;
  }
}

// Desktop
@media screen and (min-width: 1200px) {
  .nav-wrapper {
    padding-top: 20px;
  }
  .header-nav-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 50px;

    padding: 0;
  }

  .hero {
    padding-top: 0px;
    background-image: none;

    margin-right: -48px;
    margin-left: -48px;
  }

  .burger-menu {
    display: none;
  }
  .hero-logo-icon {
    margin-bottom: 43px;
  }
  .hero-desktop-flower {
    display: block;
    margin-right: auto;
    margin-left: auto;

    transform: translate(-14px, 20px);
    width: 200px;
  }

  .hero-line {
    stroke: #ebe757;
    stroke-width: 0.5px;
  }

  .hero-arrow {
    display: none;
    &:hover {
      fill: #f7f6d9;
      transform: scale(1.2);
    }

    transition: all 100ms var(--cubic);
  }

  .hero-arrow.hero-arrow-desktop {
    display: block;
  }
}
