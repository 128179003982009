.container.container__policy {
  width: 100%;
  height: 98px;
  background-color: #ba89a2;
}

.policy-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.policy__nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.policy__nav-img {
  width: 60px;
  transform: translateY(7px);
}

.policy__header-nav-link {
  font-size: 20px;
}

// Mobile
@media screen and (max-width: 767px) {
  .nav {
    font-size: 24px;
  }
}

// Tablet
@media screen and (min-width: 768px) {
}
@media screen and (max-width: 1199px) {
}

// Desktop
@media screen and (min-width: 1200px) {
  .policy__nav-img {
    width: 80px;
    margin-left: 20px;
    transform: translateY(5px);
  }

  .policy__header-nav-link {
    font-size: 24px;
  }
}
