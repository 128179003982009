:root {
  --cubic: linear;
  --shadow: 0px 10px 10px 0px rgba(0, 0, 0, .1);
}

.container.container__hero {
  width: 100%;
  background-color: #ba89a2;
}

.nav-wrapper {
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  display: flex;
}

.nav-img {
  width: 80px;
  margin-left: 20px;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

.hero {
  height: 845px;
  color: #f6ef00;
  background-color: #ba89a2;
  background-image: url("hero-bcg-mobile.77baac24.png");
  background-repeat: no-repeat;
  margin-left: -16px;
  margin-right: -16px;
  padding-top: 130px;
  font-family: Avenir Next Cyr, sans-serif;
}

.nav {
  font-family: Avenir Next Cyr, sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 96%;
}

.nav-list {
  flex-direction: column;
  gap: 15px;
  display: flex;
}

.burger-menu {
  max-width: 768px;
  width: 100%;
  height: 600px;
  z-index: 100;
  pointer-events: all;
  padding-top: 60px;
  padding-right: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

.burger-menu__wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.burger-menu__open-icon, .burger-menu__close-icon {
  fill: #f6ef00;
  position: relative;
}

.burger-menu__open-icon:active, .burger-menu__close-icon:active {
  fill: #f7f6d9;
}

.burger-menu__button {
  width: 40px;
  height: 40px;
  transition: transform .1s var(--cubic);
  background: none;
  border: none;
  margin-bottom: 60px;
  padding: 0;
}

.burger-menu__button:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.burger-nav .nav-list {
  width: 180px;
  flex-direction: column;
  gap: 56px;
  display: flex;
}

.nav-link {
  color: #f6ef00;
  transition: all .1s var(--cubic);
  line-height: 96%;
}

.nav-link:hover {
  color: #f7f6d9;
}

.ivent-time {
  text-align: center;
  margin-bottom: 44px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.hero-desktop-flower {
  display: none;
}

.hero-logo-icon, .hero-line, .hero-arrow {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.hero-line, .hero-arrow {
  fill: #ebe757;
}

.hero-logo-icon {
  fill: #f2cc21;
  width: 100%;
  margin-bottom: 176px;
  padding: 0 20px;
}

.hero-text {
  max-width: 270px;
  text-align: center;
  margin: 27px auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 96%;
  display: block;
}

.hero-arrow-link {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (max-width: 767px) {
  .hero {
    background-position: 100% 100%;
    background-size: 400px;
  }

  .nav {
    font-size: 24px;
  }
}

@media screen and (min-width: 768px) {
  .hero {
    background-position: right -250px;
    background-size: 700px;
    margin-left: -32px;
    margin-right: -32px;
  }

  .nav-link {
    font-size: 24px;
  }

  .ivent-time {
    font-size: 20px;
    font-weight: 600;
  }

  .hero-text {
    font-size: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .nav-wrapper, .hero-arrow-desktop {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .nav-wrapper {
    padding-top: 20px;
  }

  .header-nav-list {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 50px;
    padding: 0;
    display: flex;
  }

  .hero {
    background-image: none;
    margin-left: -48px;
    margin-right: -48px;
    padding-top: 0;
  }

  .burger-menu {
    display: none;
  }

  .hero-logo-icon {
    margin-bottom: 43px;
  }

  .hero-desktop-flower {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    -webkit-transform: translate(-14px, 20px);
    transform: translate(-14px, 20px);
  }

  .hero-line {
    stroke: #ebe757;
    stroke-width: .5px;
  }

  .hero-arrow {
    transition: all .1s var(--cubic);
    display: none;
  }

  .hero-arrow:hover {
    fill: #f7f6d9;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .hero-arrow.hero-arrow-desktop {
    display: block;
  }
}

.aboutUs {
  color: #393939;
  padding-top: 46px;
  font-family: Avenir Next Cyr, sans-serif;
}

.title.main-title {
  text-align: center;
  margin-top: 0;
}

.title {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Alice, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.project-text {
  margin-bottom: 15px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.title.about-you__title {
  color: #393939;
  font-family: Avenir Next Cyr, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.about-you__list {
  max-width: 400px;
  color: #393939;
  flex-direction: column;
  align-self: center;
  gap: 16px;
  margin-left: auto;
  margin-right: auto;
  font-family: Avenir Next Cyr, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.about-you__list li {
  list-style-type: disc;
  display: list-item;
}

.person-first_info-wrapper {
  flex-direction: column-reverse;
  align-items: center;
  gap: 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  display: flex;
}

.person-first__photo {
  width: 252px;
}

.person-first__questions {
  background-image: url("bcg-lines.7ec66bd8.png");
  background-repeat: no-repeat;
  margin-bottom: 20px;
  padding-top: 24px;
}

.person-second {
  padding-top: 20px;
}

.person-second__info {
  color: #404041;
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.person-second__photo {
  width: 336px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.person-second__photo-desktop {
  display: none;
}

.person-second__questions {
  padding-top: 24px;
}

.title__questions {
  margin-bottom: 20px;
}

.accordion-item__icon {
  transition: transform .1s var(--cubic);
}

.open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion {
  width: 100%;
  margin: 20px auto;
}

.accordion-item {
  background-color: #fff6fb;
  border-bottom: 1px solid;
  border-image: linear-gradient(274deg, rgba(255, 255, 255, 0) 15%, rgba(220, 91, 222, .4) 50%, rgba(255, 255, 255, 0) 85%) 1;
  margin: 10px 0;
}

.accordion-item-header {
  min-height: 50px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-family: Alice;
  font-weight: bold;
  line-height: 150%;
  display: flex;
  position: relative;
}

.accordion-item-body {
  max-height: 0;
  transition: min-height .1s ease-out;
  overflow: hidden;
}

.accordion-item-body-content {
  padding: 15px;
  line-height: 150%;
}

@media screen and (min-width: 768px) {
  .person-first__wrapper {
    width: 100%;
    height: auto;
    background-image: none;
  }

  .person-first_info-wrapper {
    width: 100%;
    height: auto;
    flex-direction: row;
    padding: 10px;
  }

  .person-first__photo {
    float: right;
    margin: 10px;
    display: block;
  }

  .person-second__photo {
    display: none;
  }

  .person-second__photo-desktop {
    float: left;
    width: 310px;
    margin: 10px 20px 10px 10px;
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .title {
    font-size: 28px;
  }

  .title.about-you__title {
    font-size: 24px;
  }

  .about-you__list {
    max-width: 600px;
    font-size: 20px;
  }

  .person-first_info-wrapper {
    font-size: 20px;
  }

  .project-text {
    margin-bottom: 48px;
    font-size: 20px;
  }

  .accordion-item, .person-second__info {
    font-size: 20px;
  }
}

.program {
  color: #393939;
  background-image: url("bcg-lines-program.304aaab7.png");
  background-position-y: 13px;
  background-repeat: no-repeat;
  background-size: 94px;
  padding-bottom: 54px;
  font-family: Avenir Next Cyr, sans-serif;
}

.title.title_program {
  margin: 0 0 21px;
}

.program-list {
  color: #393939;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 20px;
  padding: 0;
  font-family: Avenir Next Cyr, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  list-style-type: none;
  display: flex;
  position: relative;
}

li.program-list__item {
  padding-left: 4px;
  display: flex;
  position: relative;
}

.program-list__number {
  margin-right: 34px;
  font-family: Alice;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  top: 9px;
}

.program-info-wrapper {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.program-list__info {
  margin-bottom: 5px;
}

.program-list__info:first-letter {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.tech-list-title {
  text-align: center;
  margin-bottom: 10px;
}

.tech-list {
  flex-direction: column;
  gap: 5px;
  padding-left: 60px;
  display: flex;
}

.tech-list-item {
  display: list-item;
}

.bonuses-row {
  margin-bottom: 26px;
  font-size: 14px;
  font-style: italic;
  line-height: 150%;
  display: none;
}

.bonuses-list {
  max-width: 300px;
  margin-bottom: 26px;
  font-style: italic;
}

.bonuses-list__title {
  margin-bottom: 10px;
  font-weight: 600;
}

.bonuses-list__items {
  flex-direction: column;
  gap: 5px;
  margin: 0;
  padding-left: 0;
  font-size: 14px;
  display: flex;
}

.after-program-text {
  font-size: 14px;
  line-height: 150%;
}

@media screen and (min-width: 768px) {
  .program-info-wrapper {
    align-items: flex-start;
  }

  .program-list__info {
    margin-bottom: 15px;
  }

  .tech-list {
    padding-left: 40px;
  }

  .tech-list-title:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
    text-align: left;
  }

  .tech-list-title:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }

  .tech-list-title:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
    text-align: right;
  }

  .bonuses-row {
    display: block;
  }

  .bonuses-list {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .program-list__info {
    font-size: 20px;
  }

  .program-list__info:first-letter {
    font-size: 26px;
  }

  .tech-list-wrapper, .bonuses-row, .after-program-text {
    font-size: 20px;
  }
}

.service {
  background-image: url("bcg-lines-proposals.b65fc43e.png");
  background-position: right 259px;
  background-repeat: no-repeat;
  background-size: 73px;
  padding-bottom: 48px;
  font-family: Avenir Next Cyr, sans-serif;
}

.service-title-img {
  width: 40px;
}

.service-title-wrapper {
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  display: flex;
}

.title.service__title {
  color: #f2cc21;
  margin: 0 14px;
  font-size: 26px;
  font-weight: 600;
}

.title.service-info__title {
  margin: 0;
}

.title.service-info__title:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.title.service-info__title:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.title.service-info__title:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.service__wrapper {
  color: #393939;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 20px;
  display: flex;
}

.service__wrapper:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: left;
}

.service__wrapper:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.service__wrapper:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: right;
}

.service__info {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
}

.service__info-text, .service__amount {
  font-size: 14px;
  line-height: 150%;
}

.service__amount:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: right;
}

.service__amount:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.service__amount:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.service__reserve-amount {
  font-size: 10px;
  line-height: 95%;
}

.service__reserve-amount:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  text-align: right;
}

.service__reserve-amount:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.service__reserve-amount:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  text-align: left;
}

.service__button {
  width: 188px;
  height: 38px;
  color: #f2cc21;
  text-align: center;
  cursor: pointer;
  transition: all .1s var(--cubic);
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid #f2cc21;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  font-family: Alice;
  font-size: 20px;
  line-height: 150%;
}

.service__button:hover {
  box-shadow: var(--shadow);
}

.service__button:active {
  color: #f7f6d9;
  border-color: #f7f6d9;
}

@media screen and (min-width: 1200px) {
  .title.service__title {
    font-size: 26px;
  }

  .service__info {
    width: 700px;
  }

  .service__info-text, .service__amount {
    font-size: 20px;
  }

  .service__reserve-amount {
    font-size: 18px;
  }
}

.container__footer {
  width: 100%;
  background-color: #ba89a2;
}

.footer {
  color: #f6ef00;
  background-image: url("visa.aca15321.png"), url("mastercard.49c8d6f3.png");
  background-position: -4px 90%, 0 100%;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 52px;
  padding-bottom: 20px;
  font-family: Alice;
  display: flex;
  position: relative;
}

.contacts__wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  display: flex;
}

.contacts__title {
  margin: 0 15px 0 0;
  font-size: 12px;
  font-weight: 400;
}

.contacts__list {
  width: 250px;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 0;
  font-size: 12px;
  font-style: italic;
  display: flex;
}

.contacts__list a {
  -webkit-transform: color .1s var(--cubic);
  transform: color .1s var(--cubic);
}

.contacts__list a:hover {
  color: #f7f6d9;
}

.last-telegram-contact {
  margin-bottom: 15px;
}

.contacts__list-nolink-item {
  justify-content: flex-start;
  display: flex;
}

svg.footer__contact-icon--nolink {
  width: 16px;
  height: 16px;
}

.footer__contact-icon {
  width: 20px;
  height: 20px;
  fill: #f6ef00;
  margin-right: 5px;
  display: block;
}

.footer__telegram-link {
  color: inherit;
  align-items: center;
  font-size: 12px;
  font-style: italic;
  display: flex;
}

.footer__wrapper {
  max-width: 330px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  display: flex;
  position: relative;
}

.footer__title {
  font-size: 24px;
  line-height: 150%;
}

.footer-wishes {
  max-width: 133px;
  text-align: center;
  font-family: SignPainter, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
}

.footer__flower {
  position: relative;
  top: -22px;
  right: -90px;
}

.footer__policy-wrapper {
  max-width: 300px;
  font-size: 12px;
}

.footer__policy-list {
  flex-flow: column wrap;
  align-items: center;
  gap: 10px;
  margin: 0;
  padding: 0;
  display: flex;
}

.footer__policy-list-item {
  display: inline-block;
}

.footer__policy-list-item a {
  color: #f6ef00;
  -webkit-transform: color .1s var(--cubic);
  transform: color .1s var(--cubic);
}

.footer__policy-list-item a:hover {
  color: #f7f6d9;
}

@media screen and (min-width: 768px) {
  .footer {
    background-position: 87% bottom, 98% bottom;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  .footer__wrapper {
    flex-grow: 2;
    order: 2;
  }

  .contacts__wrapper {
    width: 160px;
    flex-direction: column;
    align-items: start;
    margin-bottom: 0;
  }

  .contacts__title {
    margin: 0;
  }

  .contacts__list {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__policy-wrapper {
    width: 160px;
    order: 3;
  }
}

@media screen and (min-width: 1200px) {
  .footer {
    background-position-x: 88%, 97%;
  }

  .footer__title {
    font-size: 28px;
  }

  .footer-wishes {
    max-width: 233px;
    font-size: 18px;
  }

  .contacts__wrapper {
    width: 220px;
  }

  .contacts__title {
    font-size: 18px;
  }

  .footer__contact-icon {
    width: 24px;
    height: 24px;
  }

  .footer__telegram-link {
    color: inherit;
    align-items: center;
    font-size: 16px;
    font-style: italic;
    display: flex;
  }

  .contacts__list {
    font-size: 16px;
  }

  .footer__policy-wrapper {
    width: 220px;
    font-size: 16px;
  }
}

.modal-form {
  width: 100vw;
  height: 100vh;
  z-index: 200;
  justify-content: center;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  position: fixed;
  top: 0;
  left: 15px;
}

.modal-form-wrapper {
  width: 320px;
  background-image: url("form-bcg-mobile-full.9e970fe5.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  padding: 145px 40px;
  display: flex;
}

.modal-form__close-btn {
  width: 57px;
  height: 47px;
  fill: #fff6fb;
  transition: all .1s var(--cubic);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  align-self: flex-end;
}

.modal-form__close-btn:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.modal-form__close-btn:active {
  fill: #f7f6d9;
}

.title.modal-form__title {
  color: #fff;
  margin-top: 66px;
  margin-bottom: 30px;
}

.modal-form form {
  width: 238px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.modal-form__input {
  width: 100%;
  height: 40px;
  background: #fff;
  border: none;
  border-radius: 5px;
  margin-bottom: 14px;
  padding: 8px 18px;
  font-family: Avenir Next Cyr, sans-serif;
}

.modal-form__input:focus {
  outline: none;
  box-shadow: 12px 13px 8px rgba(95, 125, 6, .2);
}

.modal-form__input.phone-input {
  margin-bottom: 45px;
  padding-left: calc(30% + 18px);
}

.modal-form__phone-lable {
  width: 30%;
  height: 40px;
  color: #6b6b6b;
  background: #fff;
  border-radius: 5px 0 0 5px;
  align-items: center;
  margin-top: -40px;
  padding: 12px 18px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  box-shadow: inset -24px 0 18px -17px rgba(34, 60, 80, .2);
}

.modal-form__submit {
  width: 188px;
  height: 38px;
  color: #fff;
  transition: all .1s var(--cubic);
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid #fff;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  font-family: Alice;
  font-size: 20px;
  line-height: 150%;
  display: block;
}

.modal-form__submit:hover {
  box-shadow: var(--shadow);
}

.modal-form__submit:active {
  color: #f7f6d9;
  border-color: #f7f6d9;
}

.modal-form__phone-warning {
  color: red;
  text-align: center;
  margin-top: -40px;
  margin-bottom: 20px;
  font-family: Avenir Next Cyr, sans-serif;
  font-weight: 600;
}

.after-submit-text {
  color: #fff;
  text-align: center;
  margin-top: 50px;
  font-family: Alice;
  font-size: 28px;
  line-height: 150%;
}

.privat-button {
  min-width: 210px;
  height: 40px;
  color: #fff;
  transition: all .1s var(--cubic);
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid #fff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-family: Alice;
  font-size: 16px;
  line-height: 150%;
  display: flex;
}

.privat-button:hover {
  box-shadow: var(--shadow);
}

.privat-button:active {
  color: #f7f6d9;
  border-color: #f7f6d9;
}

.privat-button:disabled {
  color: rgba(255, 255, 255, .5);
  border-color: rgba(255, 255, 255, .5);
}

.content-border {
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 46px 0;
  display: flex;
}

.content-border__img {
  width: 40px;
}

.content-border__img:first-child, .content-border__img:last-child {
  width: 28px;
  height: 28px;
}

.backdrop {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  background-color: rgba(108, 95, 108, .6);
  position: fixed;
  top: 1px;
  left: 1px;
}

.disabled {
  display: none;
}

.loading {
  z-index: 999;
  height: 2em;
  width: 2em;
  margin: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: visible;
}

.loading:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.loading:not(:required) {
  font: 0 / 0 a;
  color: rgba(0, 0, 0, 0);
  text-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

.loading:not(:required):after {
  content: "";
  width: 1em;
  height: 1em;
  color: red;
  -ms-animation: spinner 1.5s infinite linear;
  -webkit-box-shadow: 1.5em 0 rgba(220, 91, 222, .5), 1.1em 1.1em rgba(220, 91, 222, .5), 0 1.5em rgba(220, 91, 222, .5), -1.1em 1.1em rgba(220, 91, 222, .5), -1.5em 0 rgba(0, 0, 0, .5), -1.1em -1.1em rgba(0, 0, 0, .5), 0 -1.5em rgba(220, 91, 222, .5), 1.1em -1.1em rgba(220, 91, 222, .5);
  border-radius: .5em;
  margin-top: -.5em;
  font-size: 10px;
  -webkit-animation: 1.5s linear infinite spinner;
  animation: 1.5s linear infinite spinner;
  display: block;
  box-shadow: 1.5em 0 rgba(220, 91, 222, .5), 1.1em 1.1em rgba(220, 91, 222, .5), 0 1.5em rgba(220, 91, 222, .5), -1.1em 1.1em rgba(220, 91, 222, .5), -1.5em 0 rgba(220, 91, 222, .5), -1.1em -1.1em rgba(220, 91, 222, .5), 0 -1.5em rgba(220, 91, 222, .5), 1.1em -1.1em rgba(220, 91, 222, .5);
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.policy__container {
  min-height: 70vh;
}

.policy__content-wrapper {
  margin-bottom: 48px;
  font-family: Avenir Next Cyr, sans-serif;
  font-size: 14px;
}

.policy__main-title {
  margin-bottom: 10px;
  font-family: Alice, sans-serif;
  font-size: 24px;
}

.policy__title {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: Alice, sans-serif;
  font-size: 16px;
}

.policy__list {
  padding-left: 30px;
  list-style-type: disc;
}

.policy__list li {
  display: list-item;
}

@media screen and (min-width: 1200px) {
  .policy__main-title {
    font-size: 28px;
  }

  .policy__title {
    font-size: 18px;
  }

  .policy__content-wrapper {
    font-size: 16px;
  }
}

.container.container__policy {
  width: 100%;
  height: 98px;
  background-color: #ba89a2;
}

.policy-header {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
}

.policy__nav-wrapper {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.policy__nav-img {
  width: 60px;
  -webkit-transform: translateY(7px);
  transform: translateY(7px);
}

.policy__header-nav-link {
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .nav {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .policy__nav-img {
    width: 80px;
    margin-left: 20px;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }

  .policy__header-nav-link {
    font-size: 24px;
  }
}

@font-face {
  font-family: SignPainter;
  src: url("signpainter_housescript.6c92b33a.otf") format("woff");
}

@media screen and (min-width: 320px) {
  html, body {
    overflow-x: hidden;
  }
}

body {
  background-color: #fff6fb;
}

ul {
  list-style: none;
}

li {
  display: block;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.scrollBlock {
  overflow: hidden;
}

.blur {
  -webkit-filter: blur(9px);
  filter: blur(9px);
}

.container {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1200px;
    padding-left: 48px;
    padding-right: 48px;
  }
}

/*# sourceMappingURL=index.6f0c19bf.css.map */
